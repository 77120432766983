import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import { allData, filters } from '../../data/works';
import { shuffle } from '../../utils';
import Pagetitle from '../elements/Pagetitle';
import Portfolio from '../elements/Portfolio';

function Works() {
	const { t } = useTranslation('common');
	const [getAllItems] = useState(allData);
	const [dataVisibleCount, setDataVisibleCount] = useState(6);
	const [dataIncrement] = useState(3);
	const [activeFilter, setActiveFilter] = useState('');
	const [visibleItems, setVisibleItems] = useState([]);
	const [noMorePost, setNoMorePost] = useState(false);

	useEffect(() => {
		setActiveFilter(filters[0].text);
		shuffle(getAllItems);
		setVisibleItems(getAllItems.slice(0, 6));
	}, [getAllItems]);

	const handleChange = (e) => {
		e.preventDefault();
		console.log(e.currentTarget.value);
		setActiveFilter(e.currentTarget.value);
		let tempData;
		if (e.currentTarget.value === filters[0].text.toLowerCase()) {
			tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
		} else {
			tempData = getAllItems.filter(
				(data) => data.category === e.currentTarget.value,
			);
		}
		setVisibleItems(tempData);
	};

	const handleLoadmore = (e) => {
		e.preventDefault();
		let tempCount = dataVisibleCount + dataIncrement;
		if (dataVisibleCount > getAllItems.length) {
			setNoMorePost(true);
		} else {
			setDataVisibleCount(tempCount);
			if (activeFilter === filters[0].text) {
				setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
			} else {
				setVisibleItems(
					getAllItems.filter(
						(data) => data.category === activeFilter && data.id <= tempCount,
					),
				);
			}
		}
	};

	return (
		<section id="works">
			<div className="container">
				<Pagetitle title={t('works.title')} />
				{/* Start Portfolio Filters */}
				<ScrollAnimation
					animateIn="fadeInUp"
					animateOut="fadeInOut"
					animateOnce={true}
				>
					<ul className="portfolio-filter list-inline">
						{filters.map((filter) => (
							<li className="list-inline-item" key={filter.id}>
								<button
									onClick={handleChange}
									value={filter.text}
									className={
										filter.text === activeFilter
											? 'text-capitalize current'
											: 'text-capitalize'
									}
								>
									{t(`works.${filter.text}`)}
								</button>
							</li>
						))}
					</ul>
				</ScrollAnimation>
				{/* End Portfolio Filters */}

				{/* Start Portfolio Items */}
				<div className="row portfolio-wrapper">
					{visibleItems.map((item) => (
						<div className="col-md-4 col-sm-6 grid-item" key={item.id}>
							<Portfolio portfolio={item} />
						</div>
					))}
				</div>
				{/* End Portfolio Items */}

				<div className="load-more text-center mt-4">
					<button
						className="btn btn-default"
						onClick={handleLoadmore}
						disabled={noMorePost ? 'disabled' : null}
					>
						{noMorePost ? (
							t('action.noitemsleft')
						) : (
							<span>
								<i className="fas fa-spinner"></i>
								{t('action.load-more')}
							</span>
						)}
					</button>
				</div>
			</div>
		</section>
	);
}

export default Works;
