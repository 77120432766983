export const educationData = [
	{
		id: 1,
		title: 'experience.edu-2.title',
		company: 'ESIEE Paris',
		years: '2015 - 2018',
		content: 'experience.edu-2.bio',
	},
	{
		id: 2,
		title: 'experience.edu-1.title',
		company: 'IUT de Cachan',
		years: '2013 - 2015',
		content: 'experience.edu-1.bio',
	},
];

export const devData = [
	{
		id: 16,
		title: 'experience.mission-16.title',
		company: 'experience.mission-16.company',
		years: 'experience.mission-16.date',
		content: 'experience.mission-16.bio',
	},
	{
		id: 1,
		title: 'experience.mission-1.title',
		company: 'experience.mission-1.company',
		years: 'experience.mission-1.date',
		content: 'experience.mission-1.bio',
	},
	{
		id: 15,
		title: 'experience.mission-15.title',
		company: 'experience.mission-15.company',
		years: 'experience.mission-15.date',
		content: 'experience.mission-15.bio',
	},
	{
		id: 5,
		title: 'experience.mission-5.title',
		company: 'experience.mission-5.company',
		years: 'experience.mission-5.date',
		content: 'experience.mission-5.bio',
	},
	{
		id: 6,
		title: 'experience.mission-6.title',
		company: 'experience.mission-6.company',
		years: 'experience.mission-6.date',
		content: 'experience.mission-6.bio',
	},
	{
		id: 10,
		title: 'experience.mission-10.title',
		company: 'experience.mission-10.company',
		years: 'experience.mission-10.date',
		content: 'experience.mission-10.bio',
	},
	{
		id: 11,
		title: 'experience.mission-11.title',
		company: 'experience.mission-11.company',
		years: 'experience.mission-11.date',
		content: 'experience.mission-11.bio',
	},
	{
		id: 12,
		title: 'experience.mission-12.title',
		company: 'experience.mission-12.company',
		years: 'experience.mission-12.date',
		content: 'experience.mission-12.bio',
	},
	{
		id: 13,
		title: 'experience.mission-13.title',
		company: 'experience.mission-13.company',
		years: 'experience.mission-13.date',
		content: 'experience.mission-13.bio',
	},
];

export const siteData = [
	{
		id: 2,
		title: 'experience.mission-2.title',
		company: 'experience.mission-2.company',
		years: 'experience.mission-2.date',
		content: 'experience.mission-2.bio',
	},
	{
		id: 3,
		title: 'experience.mission-3.title',
		company: 'experience.mission-3.company',
		years: 'experience.mission-3.date',
		content: 'experience.mission-3.bio',
	},
	{
		id: 4,
		title: 'experience.mission-4.title',
		company: 'experience.mission-4.company',
		years: 'experience.mission-4.date',
		content: 'experience.mission-4.bio',
	},
	{
		id: 14,
		title: 'experience.mission-14.title',
		company: 'experience.mission-14.company',
		years: 'experience.mission-14.date',
		content: 'experience.mission-14.bio',
	},
	{
		id: 7,
		title: 'experience.mission-7.title',
		company: 'experience.mission-7.company',
		years: 'experience.mission-7.date',
		content: 'experience.mission-7.bio',
	},
	{
		id: 8,
		title: 'experience.mission-8.title',
		company: 'experience.mission-8.company',
		years: 'experience.mission-8.date',
		content: 'experience.mission-8.bio',
	},
	{
		id: 9,
		title: 'experience.mission-9.title',
		company: 'experience.mission-9.company',
		years: 'experience.mission-9.date',
		content: 'experience.mission-9.bio',
	},
];

export const siteData2 = [
	{
		id: 2,
		title: 'experience.mission-2.title',
		company: 'experience.mission-2.company',
		years: 'experience.mission-2.date',
		content: 'experience.mission-2.bio',
	},
	{
		id: 3,
		title: 'experience.mission-3.title',
		company: 'experience.mission-3.company',
		years: 'experience.mission-3.date',
		content: 'experience.mission-3.bio',
	},
	{
		id: 4,
		title: 'experience.mission-4.title',
		company: 'experience.mission-4.company',
		years: 'experience.mission-4.date',
		content: 'experience.mission-4.bio',
	},
	{
		id: 14,
		title: 'experience.mission-14.title',
		company: 'experience.mission-14.company',
		years: 'experience.mission-14.date',
		content: 'experience.mission-14.bio',
	},
];
export const siteData3 = [
	{
		id: 7,
		title: 'experience.mission-7.title',
		company: 'experience.mission-7.company',
		years: 'experience.mission-7.date',
		content: 'experience.mission-7.bio',
	},
	{
		id: 8,
		title: 'experience.mission-8.title',
		company: 'experience.mission-8.company',
		years: 'experience.mission-8.date',
		content: 'experience.mission-8.bio',
	},
	{
		id: 9,
		title: 'experience.mission-9.title',
		company: 'experience.mission-9.company',
		years: 'experience.mission-9.date',
		content: 'experience.mission-9.bio',
	},
];
