import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import Pagetitle from '../elements/Pagetitle';

function Contact() {
	const { t } = useTranslation('common');

	return (
		<section id="contact">
			<div className="container">
				<Pagetitle title={t('contact.title')} />
				<div className="contact-info">
					<ScrollAnimation
						animateIn="fadeInUp"
						animateOut="fadeInOut"
						animateOnce={true}
					>
						<h3>{t('contact.bio')}</h3>
					</ScrollAnimation>
					<ScrollAnimation
						animateIn="fadeInUp"
						animateOut="fadeInOut"
						animateOnce={true}
					>
						<p>
							{t('contact.email')}{' '}
							<a href="mailto:agenceweb@frenchisnow.com">email</a>. 👋
						</p>
					</ScrollAnimation>
				</div>
			</div>
		</section>
	);
}

export default Contact;
