import React from 'react';
import { useTranslation } from 'react-i18next';
import TrackVisibility from 'react-on-screen';
import ReactPlayer from 'react-player';
import { counterData } from '../../data/counter';
import {
	backSkills,
	frontSkills,
	langSkills,
	mainSkills,
	othersSkills,
} from '../../data/skills';
import Counter from '../elements/Counter';
import Pagetitle from '../elements/Pagetitle';
import Skill from '../elements/Skill';
import mm from '../../images/IMG_2045.MOV';
import cvEN from '../../files/COCOTIER_CV_EN.pdf';
import cvFR from '../../files/COCOTIER_CV_FR.pdf';

function About() {
	const { t, i18n } = useTranslation('common');

	const aboutMe = t('about.title');
	const cv = t('action.cv');

	return (
		<section id="about">
			<div className="container">
				<Pagetitle title={aboutMe} />
				<div className="row">
					<div className="">
						<div className="rounded bg-white shadow-dark padding-30">
							<div className="row">
								<div className="col-md-8 jt">
									<p>
										{t('about.bio')} <br /> {t('about.bio1')} <br />{' '}
										{t('about.bio2')}
									</p>
									<p>{t('about.bio3')}</p>
									<p>{t('about.bio4')}</p>
									<p>{t('about.bio5')}</p>
									<p>{t('about.bio6')}</p>
									<p>{t('about.bio7')}</p>
									<p>{t('about.bio8')}</p>
									<p>{t('about.bio9')}</p>
									<div
										className="spacer d-md-none d-lg-none"
										data-height="30"
									></div>
								</div>
								<div className="col-md-4">
									{mainSkills.map((progress) => (
										<TrackVisibility
											once
											key={progress.id}
											className="progress-wrapper"
										>
											<Skill progress={progress} />
										</TrackVisibility>
									))}
									<div className="row fix-spacing ">
										<div className="col-md-6 ">
											<TrackVisibility once className="progress-wrapper">
												<Skill progress={langSkills[0]} />
											</TrackVisibility>
										</div>
										<div className="col-md-6">
											<TrackVisibility once className="progress-wrapper">
												<Skill progress={langSkills[1]} />
											</TrackVisibility>
										</div>
									</div>
									<ReactPlayer
										playsinline
										muted={true}
										loop={true}
										className="react-player"
										playing={true}
										url={mm}
										width="100%"
										height="50%"
									/>
									<div className="jc">
										<a
											href={i18n.language === 'fr' ? cvFR : cvEN}
											download={`COCOTIER_CV_${i18n.language.toUpperCase()}.pdf`}
										>
											{cv}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="spacer" data-height="60"></div>
				<div className="rounded bg-white shadow-dark row fix-spacing ">
					<div className="padding-30 col-md-4 mb-3">
						{frontSkills.map((progress) => (
							<TrackVisibility
								once
								key={progress.id}
								className="progress-wrapper"
							>
								<Skill progress={progress} />
							</TrackVisibility>
						))}
					</div>
					<div className="padding-30 col-md-4 mb-3">
						{backSkills.map((progress) => (
							<TrackVisibility
								once
								key={progress.id}
								className="progress-wrapper"
							>
								<Skill progress={progress} />
							</TrackVisibility>
						))}
					</div>
					<div className="padding-30 col-md-4 mb-3">
						{othersSkills.map((progress) => (
							<TrackVisibility
								once
								key={progress.id}
								className="progress-wrapper"
							>
								<Skill progress={progress} />
							</TrackVisibility>
						))}
					</div>
				</div>

				<div className="spacer" data-height="70"></div>
				<div className="row fix-spacing">
					{counterData.map((counter) => (
						<div key={counter.id} className="col-md-3 col-sm-6">
							<TrackVisibility once>
								<Counter counterItem={counter} />
							</TrackVisibility>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default About;
