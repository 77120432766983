import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_fr from './locale/fr/common.json';
import common_en from './locale/en/common.json';

let defaultLanguage = 'fr';

// the translations
i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				common: common_en,
			},
			fr: {
				common: common_fr,
			},
		},
		lng: defaultLanguage,

		keySeparator: '.', // to support nested translations

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
