import moment from 'moment';

const today = moment();
const begin = moment('2017-09-25');
const countYears = today.diff(begin, 'years', true);

export const counterData = [
	{
		id: 4,
		title: 'about.years',
		count: countYears,
		icon: 'icon-graduation',
	},
	{
		id: 3,
		title: 'about.clients',
		count: 40,
		icon: 'icon-people',
	},
	{
		id: 1,
		title: 'about.projects-completed',
		count: 69,
		icon: 'icon-fire',
	},
	{
		id: 2,
		title: 'about.coffee',
		count: 2680,
		icon: 'icon-cup',
	},
];
