import { useTranslation } from 'react-i18next';
const LocaleSwitch = () => {
	const [t, i18n] = useTranslation('common');

	return (
		<div className="row mt-5">
			<div className="">
				<button
					className="btn btn-xs"
					onClick={() => i18n.changeLanguage('fr')}
				>
					<img src="images/fr.svg" alt="fr" />
				</button>
				<button
					className="btn btn-xs"
					onClick={() => i18n.changeLanguage('en')}
				>
					<img src="images/en.svg" alt="en" />
				</button>
			</div>
				<span>{t('action.lang')} </span>
		</div>
	);
};

export default LocaleSwitch;
