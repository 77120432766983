export const filters = [
	{
		id: 1,
		text: 'all',
	},
	{
		id: 2,
		text: 'web',
	},
	{
		id: 3,
		text: 'design',
	},
	{
		id: 4,
		text: 'digital',
	},
];

export const allData = [
	{
		id: 1,
		title: 'HTHU Site',
		category: 'web',
		image: 'images/works/hthu.jpg',
		link: 'https://helpthemhelpus.com/',
	},
	{
		id: 2,
		title: 'Pharaonique E-commerce',
		category: 'web',
		image: 'images/works/pharaonique.jpg',
		link: 'https://pharaonique.fr/',
	},
	{
		id: 9,
		title: 'FSM Web APP',
		category: 'web',
		image: 'images/works/point.jpg',
		popupLink: ['images/works/point.jpg'],
	},
	{
		id: 3,
		title: 'Ligaphone E-commerce',
		category: 'web',
		image: 'images/works/ligaphone.jpg',
		link: 'https://www.ligaphone-paris.com/',
	},
	{
		id: 4,
		title: 'Avocat Site ',
		category: 'web',
		image: 'images/works/ory.jpg',
		link: 'https://www.ory-avocats.com/',
	},
	{
		id: 5,
		title: 'Fashion Show Site',
		category: 'web',
		image: 'images/works/gmevent.jpg',
		link: 'https://goodmoments.fr/',
	},
	{
		id: 6,
		title: 'Elixhemp E-commerce',
		category: 'web',
		image: 'images/works/elix.jpg',
		link: 'https://elixhemp.com/',
	},
	{
		id: 7,
		title: 'Wedding Planner Site',
		category: 'web',
		image: 'images/works/alma.jpg',
		link: 'https://www.almaagency.fr/',
	},
	{
		id: 8,
		title: 'Mozs Clone',
		category: 'web',
		image: 'images/works/mozs.jpg',
		link: 'https://movz-lpv6b4mz4-frenchisnow.vercel.app/',
	},
	{
		id: 10,
		title: 'Product Design',
		category: 'digital',
		image: 'images/works/elix-1.jpg',
		popupLink: [
			'images/works/elix-1.jpg',
			'images/works/elix-2.jpg',
			'images/works/elix-3.jpg',
			'images/works/elix-4.jpg',
			'images/works/elix-5.jpg',
		],
	},
	{
		id: 11,
		title: 'Car Covering',
		category: 'digital',
		image: 'images/works/car.jpg',
		popupLink: ['images/works/car.jpg'],
	},
	{
		id: 12,
		title: 'Van Covering',
		category: 'digital',
		image: 'images/works/van-1.jpg',
		popupLink: ['images/works/van-1.jpg'],
	},
	{
		id: 13,
		title: 'Branding',
		category: 'digital',
		image: 'images/works/cart.jpg',
		popupLink: ['images/works/cart.jpg'],
	},
	{
		id: 14,
		title: 'Dashboard',
		category: 'design',
		image: 'images/works/des.jpg',
		popupLink: ['images/works/des.jpg'],
	},
	{
		id: 15,
		title: 'Bank App',
		category: 'design',
		image: 'images/works/des-1.jpg',
		popupLink: ['images/works/des-1.jpg'],
	},
	{
		id: 16,
		title: 'Network App',
		category: 'design',
		image: 'images/works/des-2.jpg',
		popupLink: ['images/works/des-2.jpg'],
	},
	{
		id: 17,
		title: 'Ecommerce App',
		category: 'design',
		image: 'images/works/des-3.jpg',
		popupLink: ['images/works/des-3.jpg'],
	},
	{
		id: 18,
		title: 'Bank App',
		category: 'design',
		image: 'images/works/des-4.jpg',
		popupLink: ['images/works/des-4.jpg'],
	},
	{
		id: 19,
		title: 'Email App',
		category: 'design',
		image: 'images/works/des-5.jpg',
		popupLink: ['images/works/des-5.jpg'],
	},
	{
		id: 20,
		title: 'Dashboard',
		category: 'design',
		image: 'images/works/des-6.jpg',
		popupLink: ['images/works/des-6.jpg'],
	},
];
