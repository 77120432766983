import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

function Timeline({ mission }) {
	const { years, title, content, company } = mission;
	const { t } = useTranslation('common');
	return (
		<ScrollAnimation
			animateIn="fadeInUp"
			animateOut="fadeInOut"
			animateOnce={true}
		>
			<div className="timeline-container">
				<div className="content mb-3">
					<span className="time">
						{t(years)} | {t(company)}{' '}
					</span>
					<h3 className="title">{t(title)}</h3>
					<p>{t(content)}</p>
				</div>
			</div>
		</ScrollAnimation>
	);
}

export default Timeline;
