export const mainSkills = [
	{
		id: 1,
		title: 'skills.development',
		percantage: 95,
		progressColor: '#6C6CE5',
		pc: false,
	},
	{
		id: 2,
		title: 'skills.ui',
		percantage: 70,
		progressColor: '#FFD15C',
		pc: false,
	},
	{
		id: 3,
		title: 'skills.project-management',
		percantage: 85,
		progressColor: '#FF4C60',
		pc: false,
	},
];
export const langSkills = [
	{
		id: 1,
		title: 'skills.francais',
		percantage: 100,
		progressColor: '#06d6a0',
		pc: false,
	},
	{
		id: 2,
		title: 'skills.anglais',
		percantage: 95,
		progressColor: '#00bbf9',
		pc: false,
	},
];

export const frontSkills = [
	{
		id: 1,
		title: 'skills.react',
		percantage: 95,
		progressColor: '#6C6CE5',
		pc: false,
	},
	{
		id: 2,
		title: 'skills.react-native',
		percantage: 60,
		progressColor: '#6C6CE5',
		pc: false,
	},
	{
		id: 3,
		title: 'skills.htmlcss',
		percantage: 90,
		progressColor: '#6C6CE5',
		pc: false,
	},
	{
		id: 4,
		title: 'skills.js',
		percantage: 90,
		progressColor: '#6C6CE5',
		pc: false,
	},
	{
		id: 6,
		title: 'skills.typescript',
		percantage: 75,
		progressColor: '#6C6CE5',
		pc: false,
	},
];
export const backSkills = [
	{
		id: 1,
		title: 'skills.graphql',
		percantage: 80,
		progressColor: '#FF4C60',
		pc: false,
	},
	{
		id: 3,
		title: 'skills.nodejs',
		percantage: 70,
		progressColor: '#FF4C60',
		pc: false,
	},
	{
		id: 4,
		title: 'skills.firebase',
		percantage: 90,
		progressColor: '#FF4C60',
		pc: false,
	},
	{
		id: 5,
		title: 'skills.aws',
		percantage: 75,
		progressColor: '#FF4C60',
		pc: false,
	},
	{
		id: 6,
		title: 'skills.python',
		percantage: 45,
		progressColor: '#FF4C60',
		pc: false,
	},
];
export const othersSkills = [
	{
		id: 1,
		title: 'skills.shopify',
		percantage: 99,
		progressColor: '#FFD15C',
		pc: false,
	},
	{
		id: 2,
		title: 'skills.wordpress',
		percantage: 99,
		progressColor: '#FFD15C',
		pc: false,
	},
	{
		id: 3,
		title: 'skills.figma',
		percantage: 75,
		progressColor: '#FFD15C',
		pc: false,
	},
	{
		id: 4,
		title: 'skills.montage',
		percantage: 85,
		progressColor: '#FFD15C',
		pc: false,
	},
	{
		id: 5,
		title: 'skills.graphisme',
		percantage: 90,
		progressColor: '#FFD15C',
		pc: false,
	},
];
