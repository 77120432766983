import React from 'react';
import { useTranslation } from 'react-i18next';
import { priceboxData } from '../../data/price';
import Pagetitle from '../elements/Pagetitle';
import Pricebox from '../elements/Pricebox';


function Pricing() {
	const { t } = useTranslation('common');
	return (
		<section id="prices">
			<div className="container">
				<Pagetitle title={t('tjm.title')} />
				<div className="row align-items-center">
					{priceboxData.map((pricebox) => (
						<div key={pricebox.id} className="col-md-4">
							<Pricebox priceboxItem={pricebox} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Pricing;
