import React from 'react';
import { useTranslation } from 'react-i18next';

function Skill({ progress, isVisible }) {
	const { t } = useTranslation('common');
	const { title, percantage, progressColor, pc } = progress;
	const winWidth = window.innerWidth;
	const progressQuery = () => {
		if (winWidth && winWidth > 767) {
			return (
				<div
					className="progress-bar data-background"
					style={
						isVisible
							? {
									width: `${percantage}%`,
									background: progressColor,
							  }
							: { width: 0, background: progressColor }
					}
				></div>
			);
		}
		return (
			<div
				className="progress-bar data-background"
				style={{
					width: `${percantage}%`,
					background: progressColor,
				}}
			></div>
		);
	};
	return (
		<div className="skill-item">
			<div className="skill-info clearfix">
				<h4 className="float-left mb-1 mt-0">{t(title)}</h4>
				{pc ? (
					<span className="float-right">{percantage}%</span>
				) : (
					<span className="mb-3"></span>
				)}
			</div>
			<div className="progress mb-4">{progressQuery()}</div>
		</div>
	);
}

export default Skill;
