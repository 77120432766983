import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomepageDark from './pages/HomepageDark';
import Bloglist from './pages/Bloglist';
import BlogDetails from './pages/BlogDetails';
import './App.scss';
// import HomepageLight from './pages/HomepageLight';

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/" exact>
					<HomepageDark />
				</Route>
				{/* <Route path="/light" exact>
					<HomepageLight />
				</Route>
				<Route path="/blogs" component={Bloglist} exact />
				<Route path="/blogs/blog-details/:id/:title" component={BlogDetails} />
		*/}
			</Switch>
		</BrowserRouter>
	);
}

export default App;
