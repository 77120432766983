import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { servicesData } from '../../data/services';
import Pagetitle from '../elements/Pagetitle';
import Service from '../elements/Service';

function Services() {
	const { t } = useTranslation('common');
	return (
		<section id="services">
			<div className="container">
				<Pagetitle title="Services" />
				<div className="row fix-spacing">
					{servicesData.map((service) => (
						<div className="col-md-4" key={service.id}>
							<Service service={service} />
						</div>
					))}
				</div>
				<div className="mt-5 text-center">
					<p className="mb-0">
						{t('action.custom-job')}{' '}
						<Link
							className="colorpink pointer"
							to="section-contact"
							spy={true}
							smooth={true}
							duration={500}
						>
							{` ${t('action.click')}`}
						</Link>{' '}
						{t('action.custom-job-end')}
					</p>
				</div>
			</div>
		</section>
	);
}

export default Services;
