import React from 'react';
import { useTranslation } from 'react-i18next';

function Pricebox({ priceboxItem }) {
	const { t } = useTranslation('common');
	const {
		icon,
		title,
		content,
		price,
		badge,
		best,
		unit,
		nego = false,
	} = priceboxItem;
	return (
		<div
			className={
				best
					? 'price-item bg-white rounded shadow-dark text-center best'
					: 'price-item bg-white rounded shadow-dark text-center'
			}
		>
			{badge && <span className="badge">{badge}</span>}
			<img src={icon} alt="Regular" />
			<h2 className="plan">{t(title)}</h2>
			{content.map((singleContent) => (
				<p key={singleContent.id}>{t(singleContent.text)}</p>
			))}
			<h3 className="price btn btn-default">
				{price}
				<em>€</em>
				{unit && <span>/ {t('tjm.day')}</span>}
			</h3>
			<div className="mt-2">{nego && <span>{t('tjm.nego')}</span>}</div>
		</div>
	);
}

export default Pricebox;
