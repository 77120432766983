import React from 'react';
import { useTranslation } from 'react-i18next';
import { devData, educationData, siteData } from '../../data/experience';
import Pagetitle from '../elements/Pagetitle';
import Timeline from '../elements/Timeline';

function Experiences() {
	const { t } = useTranslation('common');
	return (
		<section id="experience">
			<div className="container">
				<Pagetitle title={t('menu.experience')} />
				<div className="row">
					<div className="col-md-6">
						<h3 className="">{t('experience.dev')}</h3>
						<div className="spacer d-md-none d-lg-none" data-height="30"></div>
						<div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
							{devData.map((mission) => (
								<Timeline key={mission.id} mission={mission} />
							))}
							<span className="line"></span>
						</div>
					</div>
					<div className="col-md-6">
						<h3 className="">{t('experience.edu')}</h3>
						<div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
							{educationData.map((education) => (
								<Timeline key={education.id} mission={education} />
							))}
							<span className="line"></span>
						</div>
						<h3 className="">{t('experience.side')}</h3>
						<div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
							{siteData.map((mission) => (
								<Timeline key={mission.id} mission={mission} />
							))}
							<span className="line"></span>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Experiences;
