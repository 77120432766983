import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

function Header({ light, logoSource, toggleMenu, headerToggler }) {
	const { t } = useTranslation('common');
	const home = t('menu.home');
	const about = t('menu.about');
	const services = t('menu.services');
	const exp = t('menu.experience');
	const works = t('menu.works');
	const contact = t('menu.contact');

	const handleClasses = () => {
		let classes = 'desktop-header-1 d-flex align-items-start flex-column';
		if (light & toggleMenu) {
			classes += ' light open';
		} else if (toggleMenu) {
			classes += ' open';
		} else if (light) {
			classes += ' light';
		}
		return classes;
	};
	const handleMobileClasses = () => {
		let classes = 'mobile-header-1';
		if (light & toggleMenu) {
			classes += ' light open';
		} else if (toggleMenu) {
			classes += ' open';
		} else if (light) {
			classes += ' light';
		}
		return classes;
	};
	return (
		<>
			<header className={handleMobileClasses()}>
				<div className="container">
					<div className="menu-icon d-inline-flex mr-109">
						<button onClick={headerToggler}>
							<span></span>
						</button>
					</div>
				</div>
			</header>
			<header className={handleClasses()}>
				<h2 className="mb-2 mt-0">Mr COCOTIER</h2>

				<nav>
					<ul className="vertical-menu scrollspy">
						<li>
							<Link
								activeClass="active"
								to="section-home"
								spy={true}
								smooth={true}
								duration={500}
							>
								<i className="icon-home"></i>
								{home}
							</Link>
						</li>
						<li>
							<Link
								activeClass="active"
								to="section-about"
								spy={true}
								smooth={true}
								duration={500}
							>
								<i className="icon-user-following"></i>
								{about}
							</Link>
						</li>
						<li>
							<Link
								activeClass="active"
								to="section-services"
								spy={true}
								smooth={true}
								duration={500}
							>
								<i className="icon-briefcase"></i>
								{services}
							</Link>
						</li>
						<li>
							<Link
								activeClass="active"
								to="section-experiences"
								spy={true}
								smooth={true}
								duration={500}
							>
								<i className="icon-graduation"></i>
								{exp}
							</Link>
						</li>
						<li>
							<Link
								activeClass="active"
								to="section-works"
								spy={true}
								smooth={true}
								duration={500}
							>
								<i className="icon-layers"></i>
								{works}
							</Link>
						</li>
						{/* <li>
							<Link
								activeClass="active"
								to="section-blogs"
								spy={true}
								smooth={true}
								duration={500}
							>
								<i className="icon-note"></i>Blog
							</Link>
						</li> */}
						<li>
							<Link
								activeClass="active"
								to="section-contact"
								spy={true}
								smooth={true}
								duration={500}
							>
								<i className="icon-bubbles"></i>
								{contact}
							</Link>
						</li>
					</ul>
				</nav>

				<div className="footer">
					<span className="copyright">
						&copy; {new Date().getFullYear()} French Is Now.
					</span>
				</div>
			</header>
		</>
	);
}

export default Header;
