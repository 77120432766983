export const getRandomNumber = (max) => {
	return Math.floor(Math.random() * max);
};

export function shuffle(array) {
	array.sort(() => Math.random() - 0.5);
	for (let index = 0; index < array.length; index++) {
		array[index].id = index;
	}

	return array;
}
