export const servicesData = [
	{
		id: 1,
		icon: 'images/service-2.svg',
		title: 'services.web-dev',
		content: 'services.web-bio',
		color: '#6C6CE5',
		contentColor: 'dark',
	},
	{
		id: 2,
		icon: 'images/service-1.svg',
		title: 'services.ui',
		content: 'services.ui-bio',
		color: '#F9D74C',
		contentColor: 'light',
	},
	{
		id: 3,
		icon: 'images/service-3.svg',
		title: 'services.content-creation',
		content: 'services.cc-bio',
		color: '#F97B8B',
		contentColor: 'light',
	},
];
