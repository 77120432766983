export const priceboxData = [
	{
		id: 1,
		title: 'services.content-creation',
		icon: 'images/price-1.svg',
		content: [
			{
				id: 0,
				text: 'tjm.cc',
			},
			{
				id: 2,
				text: 'tjm.from',
			},
		],
		price: 129,
		best: false,
		badge: false,
	},
	{
		id: 2,
		title: 'Full Stack Dev',
		icon: 'images/price-2.svg',
		content: [
			{
				id: 1,
				text: 'tjm.fs',
			},
			{
				id: 2,
				text: 'tjm.from',
			},
		],
		price: 420,
		best: true,
		unit: true,
		badge: 'International',
		nego: true,
	},
	{
		id: 3,
		title: 'Site Web ',
		icon: 'images/price-3.svg',
		content: [
			{ id: 1, text: 'tjm.site' },
			{
				id: 2,
				text: 'tjm.from',
			},
		],
		price: 350,
	},
];
